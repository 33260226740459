.tab-content {
    text-align: center;
}

.action-buttons {
    text-align: right;
    margin: 0 auto;
    width: 351px;
    transform: translate(0%, -95%);
    -ms-transform: translate(0%, -95%);

    .btn {
        font-size: x-large;
        opacity: .75;
    }
}

.create-update-button {
    text-align: center;
    padding: 10px;
}

.capture-button {
    color: black;
    background-color: yellow;
}

.switch-camera-button {
    float: left;
    margin-top: 2%;
}

.image-tab {
    text-align: left;
}