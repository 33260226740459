.costumelist-buttons {
    width:100%;
    text-align:right;

    button {
        margin: 5px;
    }
}
.costumelist-header { 
    background-color: floralwhite;
}