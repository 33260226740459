.sizing-label {
    width: 60%;
    margin-right: 2%;
}

.sizing-value {
    width: 25%;
    margin-right: 2%;
}

.sizing-row {
    display: flex;
}

.sizing-table {
    text-align: right;
}