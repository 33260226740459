.image-display {
    text-align: center;
    width: 100%;
}

.image {
    max-width: 100%;
    max-height: 300px;
    height: auto;
}
