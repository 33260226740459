.tag-box {
    width:33%;
    display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding: 5px;
}
.tag-show-name {
    width:100%;
    text-align: center;
    font-weight: bold;
    font-size:8pt;
    text-decoration: underline;
}
.tag-customer-name {
    font-weight: bold;
    font-size: 8pt;
    width: 50%;
    display:inline-block;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
}
.tag-character-section {
    width: 50%;
    font-size: 8pt;
    display: inline-block;
    text-align: right;
}
.tag-character-name {
    font-weight: bold;
    font-size: 10pt;
}
.tag-measurement-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    display: inline-flex;   
    width: 65%;
}
.tag-notes-section {
    display: inline-flex;
    width: 35%;
    min-height: 200px;
    font-weight:bold;
    font-size: 8pt;
    padding-left: 5px;
    border-left-style: solid;
    border-left-width: 1px;
}
.tag-measurement {
    flex: 1 0 50%;
    font-size: 8pt;
}
.tag-measurement-label {
    font-weight: bold;
}
.tag-divider {
    margin-top: 5px;
    margin-bottom: 5px;
}

@media print { 
    @page {
        size: letter landscape;
    } 
    html, body {
        height:100%; 
        margin: 0 !important; 
        padding: 0 !important;
        overflow: hidden;
      }
}