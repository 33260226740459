a {
    text-decoration: none;
}
.actorlist-customer {
    font-size: 12pt;
    font-weight: bold;
}

.actorlist-info {
    flex: 0 0 50%;
    margin: auto 0;
}

.actorlist-quickprint-btn {
    button {
        font-size: 9pt;
        margin-right: 4px;
        margin-left: 4px;
    }
    flex: 0 0 100%;
    align-self: flex-end;
    font-size: 9pt;
    padding-top: 20px;
    padding-bottom: 2px;
    text-align: center;
}

.actorlist-editremove-btn {
    button {
        font-size: 9pt;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 15px;
    }
    flex: 0 0 50%;
    text-align: right;

}

.actorlist-cell {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 20px 5px 0px;
    margin-bottom: 10px;
}

.actorlist-cell:nth-of-type(even) {
    background-color: #EEEEEE
}

.actorlist-cell:nth-of-type(odd) {
    background-color: #E4E4E4
}

.actorlist-cell:first-of-type {
    border-top-style: solid;
    border-top-width: 1px;
}

