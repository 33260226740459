.update-section {
    display: flex;
}
.price-input {
    margin: 10px 10px 10px 10px;
    width: 25%;
}
.date-and-quote {
    display: flex;
}
.quote {
    font-weight: bold;
    margin: auto;
    text-align: right;
    width: 100%;
    input {
        font-weight: normal;
    }
}
.show-name {
    font-weight: bold;
    margin: 5px;
}
.show-name-example {
    font-weight: normal;
    font-size: 10pt;
    margin-left: 5px;
}
.delivery-date {
    font-weight: bold;
    margin: 5px;

    input {
        font-weight: normal;
    }
}
.actor-section span {
    font-weight: bold;
}
.editshoworder-add-button {
    width:100%;
    text-align:right;
}
.send-to-wave-btn {
    background-color: darkorange;
    color: white;
    border-color: darkorange;
    margin-left: 5px;
}