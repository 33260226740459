.showorderlist-header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    font-size: 8pt;
}
.showorderlist-body  {
    background-color: lightgrey;
}
.showorderlist-show-name {
    font-size: 12pt;
    overflow-wrap: anywhere;
    font-weight: bold;
}

.showorderlist-customer {
    flex: 1 0 25%;
    overflow-wrap: anywhere;
}
.showorderlist-quote {
    flex: 1 0 25%;
    overflow-wrap: anywhere;
}
.showorderlist-deliverydate {
    flex: 1 0 25%;
    overflow-wrap: anywhere;
}
.showorderlist-buttons {
    width:100%;
    text-align:right;

    button {
        margin-left: 5px;
    }
}
.showorderlist-add-button {
    width: 100%;
    text-align: center;
}
.showorderlist-buttons {
    text-align:right;

    button {
        margin-bottom: 5px;
    }
}

.showorderlist-info {
    flex: 0 0 50%;
    margin: auto 0;
    font-size: 10pt;
}

.showorderlist-quickprint-btn {
    button {
        font-size: 9pt;
        margin-right: 4px;
        margin-left: 4px;
    }
    flex: 0 0 100%;
    align-self: flex-end;
    font-size: 9pt;
    padding-top: 20px;
    padding-bottom: 2px;
    text-align: center;
}

.showorderlist-editremove-btn {
    button {
        font-size: 9pt;
        margin-right: 10px;
        margin-bottom: 5px;
        padding: 15px;
    }
    flex: 0 0 50%;
    text-align: right;

}

.showorderlist-cell {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 20px 5px 0px;
}

.showorderlist-cell:nth-of-type(even) {
    background-color: #EEEEEE
}

.showorderlist-cell:nth-of-type(odd) {
    background-color: #E4E4E4
}

.showorderlist-cell:first-of-type {
    border-top-style: solid;
    border-top-width: 1px;
}

.order-current-header {
    background-color: #ADFFCE;
}

.order-past-header {
    background-color: #ADCDFF;
}
