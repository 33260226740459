.dropdown {
	position: relative;

	//Validation
	&.invalid {
		select:invalid + div .slt__control {
			border: 1px solid red;
		}
    }
    
    &.valid {
        select:valid + div .slt__control {
            border: 1px solid green;
        }
    }

	select {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		font-size: 0;
	}
}