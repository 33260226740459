.add-item-div {
    padding: 2px;   
    width: 100%;
    text-align: right;
    button {
        margin-left: 5px;
        margin-bottom: 5px;
    }
    display: flex;
}

.quick-item-description-div {
    flex: 1 0 70%;
}

.quick-item-description-input { 
    width:100%;
}

.item-search {
    flex: 1 0 30%;
}

.edit-item-button {
    height: 40px;
    margin-left: 5px;
}

.remove-item-button {
    height: 40px;
    background-color: red !important;
    margin-left: 5px;
}

.item-remove-button-div {
    flex-grow: 1;
    text-align: right;
}

.items-container {
    display: flex;
    flex-wrap: wrap;
}

.item-box {
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    height: 100px;
    font-size: 8pt;
    max-height: 150px;
    display: flex;

    img {
        max-height: 75px;
    }

    .item-style .image-display {
        padding-right: 2px;
        display: flex;

        .item-description {
            text-align: left;
            flex: 1 0 50%;
        }

        .item-image {
            flex: 1 0 50%;
            text-align: right;

        }
    }
}

.price-controls {
    margin-left: 5px;
    font-size: 10pt;
}

.update-section {
    display: flex;
}

.price-input {
    margin: 10px 10px 10px 10px;
}

.description-label {
    font-weight: bold;
    margin: 5px 5px 5px 5px;
    font-size: 10pt;
}

.description-input {
    width: 80%;
    margin-left: 3%;
}