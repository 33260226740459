.customer-row {
    font-weight: bold;
    display: block;
    margin: 5px;

    ul {
        font-weight: normal;
    }
}
.customer-search {
    margin-left: 5px;
    width: 99%;
}