.top-nav-bar {
    margin: 5px 5px 10px 10px;
}

.menu-div {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: 85%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.menu-button {
    background-color: white;
    border-color: cornflowerblue;
    border-radius: 10px;
    border-width: 3px;
    width: 150px;
    height: 150px;
    color: cornflowerblue;
    margin: 10px 10px 10px 10px;
    font-weight: bold;
}