.carousel {
    z-index:0;
    border: 1px solid gray;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 45px;
    width: 45px;
    outline: black;
    border-radius: 50%;
    border: 1px solid gray;
    background-color: gray;
    padding: 10px;
    background-position-x: center;
}    

.carousel-control-prev,
.carousel-control-next {
    height: 50px;
}

.menu-buttons {

    opacity: 0.75;

    button {
        float: right;
        color: white;
        opacity: 0.75;
        font-size: 10pt;
        padding: 6px 12px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        display: block;    
    }

    .delete-button {
        background-color: #FF0000;
    }

    .select-button {
        background-color: green;
        margin-left: 0px;
        float: left;
    }
    
    .upload-button {
        background-color: #007bff;
    }

    .camera-button {
        background-color: #007bff;
    }
}

.no-images-div {
    margin-right: 35px;
    height: 68px;
    width: auto;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    margin: 0 auto;

    button {
        float: right;
        color: white;
        font-size: 25px;
        padding: 6px 12px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        display: block;
        background-color: #007bff;
    }
}

.item-style {
    min-height: 250px;
}