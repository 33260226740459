.measurements-section {
    font-weight: bold;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 15px;
    padding-bottom: 15px;
}
.costumes-section {
    font-weight: bold;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 15px;

    td {
        font-weight: normal;
    }
}
.roles-section {
    font-weight: bold;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 15px;
    padding-bottom: 15px;

    td {
        font-weight: normal;
    }
}
.update-section {
    border-top-width: 1px;
    border-top-style: solid;
}
.editactor-add-button {
    width:100%;
    text-align:right;
}