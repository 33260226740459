.filters {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;

    .filter {
        margin: 5px;
        flex: 1 0 40%;
    }
}

.apply-filter-div {
    width: 100%;
    text-align: center;
    padding-top: 10px;
}
