.costumesheet-logo {
    width: 200px;
}
.costumesheet-logo-div {
    flex: 1 0 50%;
    text-align: center;
}
.costumesheet-header {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 35px;
}
.costumesheet-info-block {
    text-align: center;
    padding-top: 20px;
    padding-left: 25px;
    flex: 1 0 50%;
}
.costumesheet-show-title {
    font-weight: bold;
    font-size: 16pt;
    font-style: italic;
}
.costumesheet-customer-name {
    font-weight: bold;
}
.costumesheet-actor-name {
    padding-top: 15px;
    font-weight: bold;
    font-size: 14pt;
}
.costumesheet-roles {
    font-weight: bold;
    font-style: italic;
}
.costumesheet-costumes {
    margin: 25px;
    width: 80%;
    margin: 0 auto;
    font-size: 8pt;
}
.costumesheet-costume {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: 15px;
}
.costumesheet-costume-pieces {
    text-align: right;
}
.costumesheet-costume-piece {
    width: 65%;
    border-bottom-style: ridge;
    float: right;
    clear: right;
}
.costumesheet-table-print-header {
    display: none;
}
@media print { 
    @page {
        size: portrait;
    }

    html, body { 
        height: initial !important;
        overflow: initial !important; 
    }
    .costumesheet-table-print-header {
        display: contents;
    }
    .costumesheet-table-print-header-showinfo {
        text-align: left;
    }
    .costumesheet-table-print-header-actorinfo {
        text-align: right;
    }
    .costumesheet-footer {
        page-break-after: always;
    }
}